import React from "react";
import { redColor } from "../helper/Colors";

function Footer() {
	return (
		<footer
			className="navbar  sticky-bottom shadow"
			style={{ backgroundColor: redColor }}
		>
			<div
				className="container d-flex justify-content-center align-items-center"
				style={{ height: "40px" }}
			>
				<p className="text-white h5">
					Copyright © 2023 badrgo Lucky Draw. Powered by badr
					technology W.L.L
				</p>
			</div>
		</footer>
	);
}

export default Footer;
