import { useEffect, useState } from "react";
import "./App.css";
import ConfettiExplosion from "react-confetti-explosion";
// import useSound from "use-sound";
import Footer from "./components/Footer.js";
import WebHeader from "./components/WebHeader.js";
const url =
	"https://us-central1-badr-77747.cloudfunctions.net/admin/lucky_draw_app_details";
const labelsTemp = {
	animatedListLabel: "Winners of QAR 500 Cash/Credit Wallet",
	banner: "https://firebasestorage.googleapis.com/v0/b/badr-77747.appspot.com/o/LuckyDrawImages%2Fbanner5.jpg?alt=media&token=d05e9320-989b-4c8f-95c2-07d454b5fc1b",
	listNamesLabel: "Qualified Passengers List",
	listWinnersLabel: "Lucky Winners",
};
function App() {
	const [labels, setLabels] = useState(labelsTemp);
	const [isAnimationStart, setIsAnimationStart] = useState(false);
	const [data, setData] = useState([]);
	const [animationData, setAnimationData] = useState([]);
	const [textData, setTextData] = useState("");
	const [winners, setWinners] = useState([]);
	const [isShowConfetti, setIsShowConfetti] = useState(false);
	const [isRemoveDrawn, setIsRemoveDrawn] = useState(true);
	const [currentWinner, setCurrentWinner] = useState("");
	const [isDisable, setIsDisable] = useState(false);
	let [isShowText, setIsShowText] = useState(true);

	// const [playConfettiSound, { stop }] = useSound();

	function submitData() {
		setData(textData.split(String.fromCharCode(10)));
	}

	function startWithDraw() {
		setIsAnimationStart(true);
		setIsShowText(true);

		///
		const randomIndex = generateRandomNumber(0, data.length);
		const winner = data[randomIndex];
		setCurrentWinner(winner);
		// check if remove drawn is checked
		if (isRemoveDrawn) {
			setData((prevArray) => {
				return prevArray.filter((_, index) => index !== randomIndex);
			});
		}
	}

	function showText() {
		setIsShowText(false);
	}

	function draw() {
		showText();
		setIsAnimationStart(false);
		setIsShowConfetti(true);
		setIsDisable(true);
		setWinners((prevArray) => {
			return [...prevArray, currentWinner];
		});
		setTimeout(() => {
			setIsShowConfetti(false);
			setIsDisable(false);
		}, 3000);
	}

	function clearData() {
		setData([]);
		setWinners([]);
		setTextData("");
		setIsShowConfetti(false);
		setIsAnimationStart(false);
		setCurrentWinner("");
		setIsDisable(false);
	}

	function generateRandomNumber(min, max) {
		return Math.floor(Math.random() * (max - min)) + min;
	}

	useEffect(() => {
		(async () => {
			try {
				const response = await fetch(url);
				if (response.status !== 200) return;
				const data = await response.json();
				if (data) setLabels(data);
			} catch (error) {
				console.log(error);
			}
		})();
	}, []);

	useEffect(() => {}, [labels]);
	useEffect(() => {
		if (data.length <= 0) setAnimationData([]);
		let startAt = 0;
		let endAt = data.length > 200 ? 200 : data.length - 1;
		setAnimationData(data.slice(startAt, endAt));
	}, [data]);

	return (
		<>
			{/* header */}
			<WebHeader />
			{/* data section */}
			<div className="d-flex flex-shrink-1" style={{ height: "450px" }}>
				{/* data set section */}
				<div
					className="card p-3 flex-grow-1"
					style={{
						borderColor: "black",
						borderWidth: "2px",
						margin: "2px",
						marginLeft: "12px",
					}}
				>
					<div className="mb-3">
						<label
							for="exampleInputEmail1"
							className="form-label h1"
						>
							{labels["listNamesLabel"]}
						</label>
						<br />
						<div className="form-floating">
							<textarea
								className="form-control"
								style={{
									height: "200px",
									borderColor: "black",
									borderWidth: "2px",
								}}
								value={textData}
								onChange={(e) => {
									setTextData(e.target.value);
								}}
							></textarea>
						</div>
					</div>

					<div className="mb-3 form-check">
						<input
							type="checkbox"
							className="form-check-input bg-danger"
							id="exampleCheck1"
							defaultChecked={true}
							checked={isRemoveDrawn}
							onChange={() => setIsRemoveDrawn(!isRemoveDrawn)}
						/>
						<label className="form-check-label" for="exampleCheck1">
							Remove Drawn Riders
						</label>
					</div>
					<div>
						<button
							className="btn btn-danger"
							style={{ fontSize: "30px", width: "220px" }}
							onClick={submitData}
							disabled={!textData}
						>
							Submit
						</button>
						<button
							className="btn btn-outline-danger"
							style={{
								marginLeft: "5px",
								marginTop: "5px",
								fontSize: "30px",
								width: "220px",
							}}
							onClick={clearData}
							disabled={!textData}
						>
							Clear List
						</button>
					</div>
				</div>

				{/* winners list section */}
				<div
					className="card p-2"
					style={{
						width: "45rem",
						borderColor: "black",
						borderWidth: "2px",
						margin: "2px",
					}}
				>
					<label
						for="exampleInputEmail1"
						className="form-label h1 mt-2"
					>
						{labels["listWinnersLabel"]}
					</label>
					<br />
					<div
						className="form-floating"
						style={{ margin: "20px", overflowX: "auto" }}
					>
						{winners.map((winner, index) => {
							return (
								<p className="h6">
									{index + 1 + " - " + winner}
								</p>
							);
						})}
					</div>
				</div>

				{/* vertical banner image */}
				<div>
					<img
						src={labels["banner"]}
						// for kia seltos
						// src="/banner3.jpg"
						// for lulu
						// src="/banner2.jpg"
						// for reward
						// src="/banner.jpg"
						alt=""
						width="400"
						height="766"
						// className="h-100"
						style={{
							margin: "4px 60px 0 60px",
							zIndex: "100",
						}}
					/>
				</div>
			</div>
			{/* animation section */}
			<div
				className="card d-flex justify-content-center align-items-center bg-transparent"
				style={{
					height: "299px",
					width: "72.4%",
					margin: " 22px 10px 22px 10px",
					borderColor: "black",
					borderWidth: "2px",
				}}
			>
				{isAnimationStart ? (
					<div className="vertical-loop-container rounded shadow p-3 mb-5 bg-body rounded">
						{animationData.map((user, index) => {
							return (
								<span
									className={
										isAnimationStart
											? "vertical-loop-text animate"
											: "vertical-loop-text"
									}
								>
									{isShowText ? (
										<p className="h1">{user}</p>
									) : (
										<></>
									)}
								</span>
							);
						})}
					</div>
				) : (
					<div className="vertical-loop-container rounded shadow p-3 mb-5 bg-body rounded">
						<span
							className={
								isAnimationStart
									? "vertical-loop-text animate"
									: "vertical-loop-text"
							}
						>
							<p className="h1">
								{!currentWinner && data.length > 0
									? data[0]
									: !currentWinner && data.length <= 0
									? labels["animatedListLabel"]
									: currentWinner}
							</p>
						</span>
					</div>
				)}
				{isShowConfetti ? (
					<ConfettiExplosion
						force={0.8}
						duration={3000}
						particleCount={900}
						width={2000}
					/>
				) : (
					<></>
				)}
				<button
					type="submit"
					className="btn btn-danger"
					style={{
						width: "220px",
						marginTop: "20px",
						fontSize: "30px",
					}}
					onClick={isAnimationStart ? draw : startWithDraw}
					disabled={data.length <= 0 || isDisable}
				>
					{isAnimationStart ? "Stop" : "Start"}
				</button>
			</div>
			{/* footer */}
			<Footer />
		</>
	);
}

export default App;
