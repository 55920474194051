import React from "react";
import { redColor } from "../helper/Colors";

function WebHeader() {
	return (
		<header
			className="navbar sticky-top shadow "
			style={{ backgroundColor: redColor }}
		>
			<div className="container-fluid d-flex align-items-center justify-content-start">
				<a
					className="navbar-brand d-flex justify-content-center align-items-center flex-grow-0"
					href="https://badrgo.com"
				>
					<img
						src="/bgwhite1.png"
						alt="badrgo Lucky Draw"
						width="190"
						height="80"
						className="text-white"
						style={{ marginLeft: "10rem" }}
					/>
				</a>
				{/* <a
					className="navbar-brand d-flex justify-content-center align-items-center flex-grow-1 "
					href="https://badrgo.com"
				>
					<img
						src="/bgwhite1.png"
						alt="badrgo Lucky Draw"
						width="190"
						height="80"
						className="text-white"
					/>
				</a> */}
			</div>
		</header>
	);
}

export default WebHeader;
